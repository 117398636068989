

export interface EnquirySubType {
    id: number;
    lookUpOrder: number;
    name: string;
}

export class EnquiryItem {
    enquiryDate: string;
    enquiryId: number;
    placeId: number;
    enquirySubType: EnquirySubType;

    static GetEnquiryTypeText(enquirySubType: EnquirySubType): string {
        switch (enquirySubType.id) {
            case 0:
            case 3:
                return "You made an enquiry about this property.";
            case 1:
                return "You requested to view this property.";
            case 2:
                return "You requested more details about this property.";
            default:
                return ""; // Add a default return statement to handle any other cases
        }
    }

    static GetTagText(enquirySubType: EnquirySubType, enquiryDateFormatted: string): any {
        let text = ""
        let iconClass = "icon-sent"
        switch (enquirySubType.id) {
            case 0:
            case 3:
            case 2:
                text = "Enquiry sent {DATE}";
                break;
            case 1:
                iconClass = "icon-calendar";
                text = "Viewing request sent {DATE}";
                break;
        }

        if (enquiryDateFormatted) {
            if (new RegExp("[0-9]").test(enquiryDateFormatted)) {
                text = text.replace("{DATE}", "on " + enquiryDateFormatted);
            }
            else {
                text = text.replace("{DATE}", enquiryDateFormatted.toLowerCase());
            }
        }

        return { caption: text, iconClass: iconClass };
    }


    address: string;
    agentAddress: string;
    agentName: string;
    agentPhoneNumber: string;
    detailsUrl: string;
    enquiryMessage: string;
    photos: Array<string>;
    postcode: string;
    price: string;
    priceModifier: string;
    title: string;
    isHidden: boolean;
};

