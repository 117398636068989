import { Injectable, Inject } from "@angular/core";
import { BROWSER_STORAGE_KEYS } from "src/app/infrastructure/storage.keys";
import { ISubscriber } from "../messenger.module/model/model";
import { MESSAGE_TYPES } from "src/app/infrastructure/message.types";
import { EnquiryItem } from "src/app/modules/isolated.components/enquiry.item.component/model";
import { ApplicationStateService } from "../application.state.module/application.state.service";
import { PlatformHelpersService } from "../platform.helpers.module/platform.helpers.service";
import { BrowserStorageService } from "../browser.storage.module/browser.storage.service";
import { Messenger } from "../messenger.module/messenger";


@Injectable({providedIn: 'root'})
export class EnquiriesService {
    private enquiries: Array<EnquiryItem>;
    private refreshTimeout: any;

    private meStateChangedSubscriber: ISubscriber = {
        messageType: MESSAGE_TYPES.ME_STATE_CHANGED,
        On: () => {
            this.InitializeEnquiries();
        }
    }

    constructor(
        private applicationStateService: ApplicationStateService,
        private platformService: PlatformHelpersService,
        private browserStorageService: BrowserStorageService,
        private messenger: Messenger
    ) {
        this.messenger.Subscribe(this.meStateChangedSubscriber);
        if (!this.enquiries) {
            this.InitializeEnquiries();
        }
    }

    get shouldProcessEnquiries(): boolean {
        return this.platformService.IsBrowserPlatform && !!this.applicationStateService.me;
    }

    GetEnquiries(): Array<EnquiryItem> {
        if (!this.enquiries) {
            this.InitializeEnquiries();
        }
        return this.enquiries;
    }

    GetEnquiryForPlaceId(placeId: number): EnquiryItem {
        if (this.shouldProcessEnquiries) {
            return this.enquiries.find(e => e.placeId === placeId);
        }
        return null;
    }

    HideEnquiry(enquiryId: number) {
        var enquiryInMe = this.applicationStateService.me.placeEnquiries.find(e => e.enquiryId === enquiryId);
        if (enquiryInMe) {
            this.applicationStateService.me.placeEnquiries.splice(this.applicationStateService.me.placeEnquiries.indexOf(enquiryInMe), 1);
            this.applicationStateService.RefreshMeFromServer();
        }
    }

    
    SavePendingEnquiry(enquiry: EnquiryItem) {
        if (this.platformService.IsBrowserPlatform) {
            let pendingEnquiries: Array<EnquiryItem> = this.browserStorageService.GetLocal(BROWSER_STORAGE_KEYS.PENDING_ENQUIRIES);
            if (!pendingEnquiries) { pendingEnquiries = new Array<EnquiryItem>(); }
            enquiry.enquiryDate = new Date().toISOString();
            pendingEnquiries.push(enquiry);
            this.browserStorageService.SetLocal(BROWSER_STORAGE_KEYS.PENDING_ENQUIRIES, pendingEnquiries);
            this.InitializeEnquiries();
            if (this.refreshTimeout) { clearTimeout(this.refreshTimeout); }
            this.refreshTimeout = setTimeout(() => {
                this.applicationStateService.RefreshMeFromServer(); // Initialize will be triggered by the meStateChangedSubscriber
            }, 60000)
        }
    }

    private InitializeEnquiries() {
        if (this.shouldProcessEnquiries && this.applicationStateService.me.placeEnquiries) {
            this.enquiries = new Array<EnquiryItem>();
            for (let serverEnquiries of this.applicationStateService.me.placeEnquiries) {
                this.enquiries.push(serverEnquiries);
            }

            let pendingEnquiries = new Array<EnquiryItem>();
            let localEnquiries: Array<EnquiryItem> = this.browserStorageService.GetLocal(BROWSER_STORAGE_KEYS.PENDING_ENQUIRIES);
            if (localEnquiries) {
                for (let localEnquiry of localEnquiries) {
                    if (!this.enquiries.find(e => e.placeId === localEnquiry.placeId)) {
                        pendingEnquiries.push(localEnquiry);
                    }
                }

                for (let pendingEnquiry of pendingEnquiries) {
                    this.enquiries.splice(0, 0, pendingEnquiry);
                }
                this.browserStorageService.SetLocal(BROWSER_STORAGE_KEYS.PENDING_ENQUIRIES, pendingEnquiries);
            }
        }
        else {
            this.enquiries = [];
        }

        this.messenger.Send({
            messageType: MESSAGE_TYPES.ENQUIRIES_UPDATED,
            messageData: null
        });
    }
}
